import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Card, Col, Row, Container, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { BsSave2 } from "react-icons/bs";
import TextField from '@mui/material/TextField';
import Api from '../../../../services/Api';
import Header from '../../../../components/Header/Header';
import ButtonBack from '../../../../components/ButtonBack/ButtonBack';
import Loader from '../../../../components/Loader/Loader';

const EditFormulario = (props) => {
  const navigate = useNavigate()
  const sha1Curva = JSON.parse(localStorage.getItem('sha1Curva'))
  const [curva] = useState(JSON.parse(localStorage.getItem('curva')))
  const [equipaments, setEquipaments] = useState(JSON.parse(localStorage.getItem('instruments')))
  const [empresa] = useState(JSON.parse(localStorage.getItem('empresa')))
  const [bool, setBool] = useState(true)
  const [chartby, setGetchartby] = useState([])

  const token = empresa.token;
  const email = empresa.notification.notifyTo

  const handlePost = async () => {
    const teste = handleConfirm()
    if (teste === true) {
      try {
        const data = await Api.put('/instruments/updateinstruments', equipaments, { headers: { 'x-access-token': token } })
        if (data.status === 200) {
          handleEmail()
          navigate("/editarequipamentos")
        } else {
          alert('Erro ao atualizar.')
        }
      } catch (error) {
        alert('Erro ao atualizar.')
      }
    }
  }

  const isMaxGreaterThanMin = (max, min) => {
    return max >= min;
  };
  const handleConfirm = () => {
    const entrada = equipaments.equipamentos.entrada.configs;
    const saida = equipaments.equipamentos.saida.configs;

    if (!isMaxGreaterThanMin(entrada.maxHumidity, entrada.minHumidity) ||
      !isMaxGreaterThanMin(entrada.maxTemperature, entrada.minTemperature) ||
      !isMaxGreaterThanMin(entrada.maxPH, entrada.minPH) ||
      !isMaxGreaterThanMin(saida.maxHumidity, saida.minHumidity) ||
      !isMaxGreaterThanMin(saida.maxTemperature, saida.minTemperature) ||
      !isMaxGreaterThanMin(saida.maxPH, saida.minPH)) {
      alert('O valor máximo não pode ser menor que o mínimo');
      return false;
    } else {
      return true;
    }
  };

  const handleEmail = async () => {
    if (empresa.notification.changeCurve === true && curva !== equipaments.curva && empresa.channel.email === true) {
      try {
        await Api.post(`/email`, { "email": email, "code": 'Curva alterada para: ' + equipaments.curva })
      } catch (err) {
        alert('Erro ao atualizar.')
      }
    }
    return;
  }

  const handleSelect = async () => {
    try {
      let chartsby = await Api.get(`/charts/grains/066faa312c568f68cd2b5206149065b1c2a87048`)
      chartsby.data.push("ARROZ EM CASCA,LONGO GRAO PARBO");
      setGetchartby(chartsby.data)
      setBool(false)
    } catch (error) {
      alert("Ocorreu um erro ao buscar as curvas.");
      setBool(false)
    }
  }

  useEffect(() => {
    handleSelect()
  }, [])

  return (
    <>
      <Header />
      {bool && <Loader />}
      <Container fluid>
        <Card className='mb-3 mt-3 p-3'>

          <Row>
            <Col xl={6}>
              <Form.Control as="select" className="mb-0 mt-2"
                onChange={(event) => {
                  equipaments.curva = event.target.value;
                  setEquipaments({ ...equipaments });
                }}
                id="curva"
              >

                {chartby && chartby.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Form.Control>
            </Col>
            <Col xl={6}>
              <Form.Control as="select" className="mb-0 mt-2"
                onChange={(event) => {
                  equipaments.sha1Curva = (event.target.value.replace('.cur', ''));
                  setEquipaments({ ...equipaments });
                }}
                disabled={equipaments.sha1Curva ? true : false}
              >
                <option>{equipaments.sha1Curva ? equipaments.sha1Curva : "Curva"}</option>
                {sha1Curva && sha1Curva.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Form.Control>
            </Col>
          </Row>

          <Row className='p-2'>
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="name"
              label="Nome"
              variant="outlined"
              type="text"
              onChange={(event) => {
                equipaments.name = event.target.value;
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.name ? equipaments.name : ''}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="sha1curva"
              label="Sha1Curva"
              variant="outlined"
              type="text"
              value={equipaments.sha1Curva ? equipaments.sha1Curva : ''}
              defaultValue="Small"
              size="small"
              disabled
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="curva"
              label="Grão"
              variant="outlined"
              type="text"
              value={equipaments.curva ? equipaments.curva : ''}
              defaultValue="Small"
              size="small"
              disabled
            />
          </Row>

          <Row>
            <Form.Label style={{ "background": "rgba(41, 140, 101, 0.6)", "padding": "10px" }}> Instrumento de entrada - {equipaments.equipamentos.entrada.ns} </Form.Label>
          </Row>

          <Row className='p-3'>
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="maxHumidity"
              label="Max umidade entrada"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.entrada.configs.maxHumidity = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.entrada.configs.maxHumidity}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="maxTemperature"
              label="Max temperatura entrada"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.entrada.configs.maxTemperature = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.entrada.configs.maxTemperature}
              defaultValue="Small"
              size="small"
            />
            <TextField
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="maxPH"
              label="Max PH entrada"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.entrada.configs.maxPH = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.entrada.configs.maxPH}
              defaultValue="Small"
              size="small"
            />

            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="minHumidity"
              label="Min umidade entrada"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.entrada.configs.minHumidity = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.entrada.configs.minHumidity}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="minTemperature"
              label="Min temperatura entrada"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.entrada.configs.minTemperature = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.entrada.configs.minTemperature}
              defaultValue="Small"
              size="small"
            />
            <TextField
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="minPH"
              label="Min PH entrada"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.entrada.configs.minPH = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.entrada.configs.minPH}
              defaultValue="Small"
              size="small"
            />
          </Row>

          <Row>
            <Form.Label style={{ "background": "rgba(41, 140, 101, 0.6)", "padding": "10px" }}> Instrumento de saida - {equipaments.equipamentos.saida.ns} </Form.Label>
          </Row>

          <Row className='p-3'>
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="maxHumidity"
              label="Max umidade saida"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.saida.configs.maxHumidity = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.saida.configs.maxHumidity}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="maxTemperature"
              label="Max temperatura saida"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.saida.configs.maxTemperature = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.saida.configs.maxTemperature}
              defaultValue="Small"
              size="small"
            />
            <TextField
              className="mb-3 mt-2 col-sm-4"
              autoComplete="off"
              id="maxPH"
              label="Max PH saida"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.saida.configs.maxPH = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.saida.configs.maxPH}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-2 mt-2 col-sm-4"
              autoComplete="off"
              id="minHumidity"
              label="Min umidade saida"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.saida.configs.minHumidity = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.saida.configs.minHumidity}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-2 mt-2 col-sm-4"
              autoComplete="off"
              id="minTemperature"
              label="Min temperatura saida"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.saida.configs.minTemperature = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.saida.configs.minTemperature}
              defaultValue="Small"
              size="small"
            />
            <TextField
              className="mb-2 mt-2 col-sm-4"
              autoComplete="off"
              id="minPH"
              label="Min PH saida"
              variant="outlined"
              type="number"
              onChange={(event) => {
                equipaments.equipamentos.saida.configs.minPH = Number(event.target.value);
                setEquipaments({ ...equipaments });
              }}
              value={equipaments.equipamentos.saida.configs.minPH}
              defaultValue="Small"
              size="small"
            />
          </Row>
          <Col>
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => { handlePost() }} >
              <BsSave2 /> Salvar
            </Button>
          </Col>
        </Card>
      </Container>
    </>
  )
}
export default EditFormulario
